import { render, staticRenderFns } from "./closeAccount.vue?vue&type=template&id=e97c9c10&scoped=true"
import script from "./closeAccount.vue?vue&type=script&lang=js"
export * from "./closeAccount.vue?vue&type=script&lang=js"
import style0 from "./closeAccount.vue?vue&type=style&index=0&id=e97c9c10&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e97c9c10",
  null
  
)

export default component.exports